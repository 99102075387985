




















































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";

export default Vue.extend({
  data() {
    return {
      item: {},
      data: [],
      dataCount: 0,
      searchPackageId: "",
      searchPackageBatchId: "",
      sortBy: "",
      sortType: "",
      sortByFields: [
        {
          value: "package_batch_id",
          text: "Mã lô",
        },
        {
          value: "delta_weight",
          text: "Lệch cân",
        },
        {
          value: "delta_volume",
          text: "Lệch khối",
        },
      ],
      sortTypes: [
        {
          value: "asc",
          text: "Tăng dần",
        },
        {
          value: "desc",
          text: "Giảm dần",
        },
      ],
      loading: false,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    sortBy() {
      this.initialize();
    },
    sortType() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.item = await apiClient.truckGet(this.$route.params.id);
      const { items, count } = await apiClient.packageGetAll({
        rawOptions: {
          limit: -1,
        },
        filters: [
          {
            key: "package_id",
            operator: "match",
            value: this.searchPackageId,
          },
          {
            key: "package_batch_id",
            operator: "match",
            value: this.searchPackageBatchId,
          },
          {
            key: "truck_id",
            operator: "equal_to",
            value: this.$route.params.id,
          },
        ],
      });
      items.forEach((item) => {
        if (!item.weight_items) {
          item.weight_items = [];
        }
        item.weight_items.forEach((item) => {
          item.editted = false;
          if (!item.disabled) {
            item.disabled = false;
          }
        });
        if (!item.weight_items.length) {
          item.weight_items.push({
            editted: true,
            disabled: false,
          });
        }
      });
      const self = this;
      const getField = (item) => {
        let value = item[self.sortBy];
        if (self.sortBy === "delta_weight") {
          value = self.deltaWeight(item);
        } else if (self.sortBy === "delta_volume") {
          value = self.deltaVolume(item);
        }
        return value;
      };
      const compare = (value1, value2) => {
        let result = 0;
        if (typeof value1 === "number") {
          result = value1 - value2;
        } else {
          value1 = value1 || "";
          value2 = value2 || "";
          result = value1.localeCompare(value2);
        }
        if (result !== 0) {
          if (self.sortType === "desc") {
            result *= -1;
          }
        }
        return result;
      };
      if (this.sortBy && this.sortType) {
        items.sort((item1, item2) => {
          const value1 = getField(item1);
          const value2 = getField(item2);
          return compare(value1, value2);
        });
      }
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    rowCount(pkg) {
      return pkg.weight_items.filter((wi) => !wi.disabled).length;
    },
    firstRowIndex(pkg) {
      return pkg.weight_items.findIndex((wi) => !wi.disabled);
    },
    lastRowIndex(pkg) {
      return pkg.weight_items.findLastIndex((wi) => !wi.disabled);
    },
    addWeightItem(pkg) {
      pkg.weight_items.push({
        editted: true,
        disabled: false,
      });
    },
    deleteWeightItem(pkg, index) {
      const weightItem = pkg.weight_items[index];
      if (weightItem.id) {
        weightItem.disabled = true;
      } else {
        pkg.weight_items.splice(index, 1);
      }
      if (!pkg.weight_items.length) {
        pkg.weight_items.push({
          editted: false,
          disabled: false,
        });
      }
    },
    editWeightItem(pkg, index) {
      pkg.weight_items[index].editted = true;
    },
    weightVn(pkg) {
      return pkg.weight_items.reduce((value, item) => {
        return value + (item.weight || 0) * (item.count || 0);
      }, 0);
    },
    volumeVn(pkg) {
      return pkg.weight_items.reduce((value, item) => {
        return (
          value +
          ((item.length || 0) *
            (item.width || 0) *
            (item.height || 0) *
            (item.count || 0)) /
            1000000
        );
      }, 0);
    },
    countVn(pkg) {
      return pkg.weight_items.reduce((value, item) => {
        return value + (+item.count || 0);
      }, 0);
    },
    deltaWeight(pkg) {
      return this.weightVn(pkg) - (+(pkg.real_weight || pkg.weight) || 0);
    },
    deltaVolume(pkg) {
      return this.volumeVn(pkg) - (+(pkg.real_volume || pkg.volume) || 0);
    },
    deltaCount(pkg) {
      return this.countVn(pkg) - (+(pkg.real_count || pkg.count) || 0);
    },
    async update(pkg, preventConfirm) {
      if (!preventConfirm) {
        if (!confirm("Bạn có muốn tiếp tục?")) {
          return;
        }
      }
      await apiClient.packageUpdateWeightItems(
        pkg.id,
        {
          weight: this.weightVn(pkg),
          volume: this.volumeVn(pkg),
          count: this.countVn(pkg),
        },
        pkg.weight_items.map((wi) => {
          const wi2 = JSON.parse(JSON.stringify(wi));
          delete wi2.editted;
          return wi2;
        })
      );
      pkg.weight_items.forEach((item) => {
        item.editted = false;
      });
    },
    async updateAll() {
      if (!confirm("Sửa tất cả đơn hàng?")) {
        return;
      }
      for (const pkg of this.data) {
        await this.update(pkg, true);
      }
      alert("Cập nhật thành công");
    },
    async updateDoneStatus(pkg) {
      await apiClient.packageUpdateWeightDoneStatus(pkg.id, "done");
      pkg.weight_done_status = "done";
    },
  },
});
